import React from 'react'
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Table as MUITable, TableHead, TableRow, TableBody, TableCell, TableBodyProps, TableProps as MUITableProps, TableCellProps as MUITableCellProps, TableHeadProps, Box } from '@mui/material'
import { FONT_STYLES_BY_TYPE } from './styled/Text'

export const TableHeaderCell = ({ sx = { }, children, ...restProps}: MUITableCellProps) => {
    return (
        <TableCell sx={{
            backgroundColor: (theme) => (theme.palette.secondary as any).main2,
            borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
            color: (theme) => theme.palette.primary.main,
            font: FONT_STYLES_BY_TYPE.table_header,
            ...sx,
        }} {...restProps}>{children}</TableCell>
    )
}

export interface TableHeadCellProps extends MUITableCellProps{
    key?: string
}
export interface TableHeadRowProps extends MUITableCellProps{
    key?: string
    columns: TableHeadCellProps[]
}
export interface TableHeadCellProps extends MUITableCellProps {
    key?: string;
    sortKey?: string;             // unique key to identify column data
    isSortable?: boolean;         // whether this column is sortable
}
  
export interface TableProps extends MUITableProps {
    bodyProps?: TableBodyProps;
    headerColumns?: TableHeadCellProps[];
    headerRows?: TableHeadRowProps[];
    headProps?: TableHeadProps;

    // Sorting-related props
    onSort?: (sortKey: string) => void;        // callback for column click
    orderBy?: string;                          // which column is currently sorted
    orderDirection?: 'asc' | 'desc';           // current sort direction
}
  

export const Table = ({
    sx = {},
    children,
    headerColumns = [],
    headerRows = [],
    bodyProps,
    headProps = {},
    onSort,
    orderBy,
    orderDirection,
    ...restProps
  }: TableProps) => {
    const headers = headerColumns.length > 0
      ? [...headerRows, { key: '', columns: headerColumns }]
      : headerRows;
  
    const hasHead = headers?.length > 0;
  
    return (
      <MUITable
        sx={{
          borderSpacing: '0 !important',
          borderCollapse: 'collapse !important',
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          ...sx,
          '& td': {
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            ...(sx && sx['& td'] ? sx['& td'] : {}),
          },
        }}
        {...restProps}
      >
        {hasHead && (
          <TableHead
            sx={{ border: (theme) => `1px solid ${theme.palette.primary.main}` }}
            {...headProps}
          >
            {headers.map((row, rowIndex) => (
              <TableRow key={row.key ?? rowIndex}>
                {row.columns.map((header: any, colIndex: number) => {
                  const { isSortable, sortKey, children: headerLabel, ...cellProps } = header;
  
                  // Does this column match the sorted column?
                  const isSorted = !!(orderBy && sortKey && orderBy === sortKey);
  
                  // If sortable, we wrap the label and icon in a clickable region
                  if (isSortable && sortKey && onSort) {
                    return (
                      <TableHeaderCell
                        key={sortKey || String(colIndex)}
                        onClick={() => onSort(sortKey)}
                        sx={{
                          cursor: 'pointer',
                          userSelect: 'none',
                          ...(cellProps.sx || {}),
                        }}
                        {...cellProps}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <span>{headerLabel}</span>
                          {isSorted && orderDirection === 'asc' && (
                            <ExpandLess fontSize="small" style={{ marginLeft: 4 }} />
                          )}
                          {isSorted && orderDirection === 'desc' && (
                            <ExpandMore fontSize="small" style={{ marginLeft: 4 }} />
                          )}
                        </Box>
                      </TableHeaderCell>
                    );
                  }
  
                  // Non-sortable column or missing sortKey -> standard cell
                  return (
                    <TableHeaderCell
                      key={sortKey || String(colIndex)}
                      {...cellProps}
                    >
                      {headerLabel}
                    </TableHeaderCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
        )}
  
        <TableBody {...bodyProps}>{children}</TableBody>
      </MUITable>
    );
  };
  