import { Component } from 'react'
import { Box } from '@mui/material';
import { Ticker, SnapshotItems, TickerAllocation, ETFTicker, MetricDataMapped } from '../../actions/types/portfolioDesigner';
import Text from '../common/Text';
import TooltipContainer from '../common/styled/TooltipContainer';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { BaseProps } from '../types';

const HAS_DESCRIPTION_FIELDS: (keyof ETFTicker)[] = [
    'description',
    'asset_class',
    'launch_date',
    'expense_ratio',
    'etf_asset_class',
    'etf_launch_date',
    'etf_expense_ratio',
    'etf_desc',
]

interface TickerToolTipContainerProps extends BaseProps {
    ticker?: Ticker
    disabled?: boolean
    snapshots: SnapshotItems
    metrics: MetricDataMapped
    snapshotLoading: boolean
    snapshotTargetItem?: TickerAllocation
    snapshotTargetItemUpdateType?: 'weight' | 'ticker'
    showMCTR?: boolean
    children?: JSX.Element
}

class TickerToolTipContainer extends Component<TickerToolTipContainerProps> {
    render() {
        const { sx = {}, ticker, disabled, metrics = {}, snapshots = {}, snapshotLoading, snapshotTargetItem, snapshotTargetItemUpdateType, showMCTR, children } = this.props

        const symbolDisplay = ticker?.symbol?.endsWith('-US') ? ticker?.symbol_display : ticker?.symbol
        const snapshotItem = snapshots[symbolDisplay || '']
        const tooltipDisabled = disabled || !ticker;
        const showSnapshot = !tooltipDisabled
        const isTargetItem = snapshotLoading && !snapshotTargetItem || snapshotTargetItem?.ticker?.id === ticker?.id
        const distribution_yield = metrics.distribution_yield?.[symbolDisplay || '']?.toFixed(2)
        const duration = metrics.duration?.[symbolDisplay || '']?.toFixed(2)
        const yield_to_maturity = metrics.yield_to_maturity?.[symbolDisplay || '']?.toFixed(2)
        const price_over_earnings = metrics.price_over_earnings?.[symbolDisplay || ''] ? Number(metrics.price_over_earnings?.[symbolDisplay || '']).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        }) : undefined
        
        const hasFields = Object.keys(ticker || {}).find((key: string) => HAS_DESCRIPTION_FIELDS.includes(key as keyof ETFTicker)) || !!distribution_yield || !!duration

        return (
            <TooltipContainer
                key={ticker?.id}
                hoverDisabled={tooltipDisabled}
                hoverContentsSx={{ padding: '1rem', marginTop: '1rem' }}
                sx={sx}
                hoverContents={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Text sx={{ fontWeight: 'bold' }} noGlossary>{ticker?.symbol_display}</Text>
                        {hasFields &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '2rem', }}>
                                {((ticker as ETFTicker)?.etf_desc ?? ticker?.description) && <Text noGlossary>{(ticker as ETFTicker)?.etf_desc ?? ticker?.description}</Text>}

                                {ticker?.asset_class && <Box sx={{marginTop: '1rem'}}><Text>Asset Class: </Text><Text noGlossary>{(ticker as Ticker).asset_class}</Text></Box>}
                                {ticker?.launch_date && <Box><Text>Inception Date: </Text><Text noGlossary>{moment((ticker as Ticker).launch_date).format('MM/DD/YYYY')}</Text></Box>}
                                {ticker?.expense_ratio && <Box><Text>Expense Ratio: </Text><Text noGlossary>{(ticker?.expense_ratio / 100 / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2})}</Text></Box>}

                                {(ticker as ETFTicker)?.etf_asset_class && <Box sx={{marginTop: '1rem'}}><Text>Asset Class: </Text><Text noGlossary>{(ticker as ETFTicker).etf_asset_class}</Text></Box>}
                                {(ticker as ETFTicker)?.etf_launch_date && <Box><Text>Inception Date: </Text><Text noGlossary>{moment((ticker as ETFTicker).etf_launch_date).format('MM/DD/YYYY')}</Text></Box>}
                                {(ticker as ETFTicker)?.etf_expense_ratio && <Box><Text>Expense Ratio: </Text><Text noGlossary>{((ticker as ETFTicker).etf_expense_ratio / 100 / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2})}</Text></Box>}
                                {distribution_yield &&
                                    <Box><Text>Distribution Rate: </Text><Text noGlossary>{(Number(distribution_yield)).toFixed(2)}%</Text></Box>
                                }
                                {yield_to_maturity &&
                                    <Box><Text>Yield To Maturity: </Text><Text noGlossary>{(Number(yield_to_maturity)).toFixed(2)}%</Text></Box>
                                }
                                {duration &&
                                    <Box><Text>Effective Duration: </Text><Text noGlossary>{(Number(duration)).toFixed(2)} Years</Text></Box>
                                }
                                {price_over_earnings &&
                                    <Box><Text>Average P/E: </Text><Text noGlossary>{price_over_earnings}</Text></Box>
                                }    
                            </Box>
                        }
                        {snapshotLoading && !snapshotItem?.snapshot &&
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ClipLoader />
                            </Box>
                        }
                        {snapshotItem &&
                            Object.keys(snapshotItem?.snapshot).map((key) => {
                                return (
                                    <Text key={key} noGlossary>{key}: {isTargetItem && snapshotTargetItemUpdateType === 'ticker' ? <ClipLoader loading={true} size={14} /> : snapshotItem.snapshot[key] as any}</Text>
                                )
                            })
                        }
                        {snapshotItem && showMCTR &&
                            <Box><Text>MCTR: </Text><Text noGlossary>{snapshotLoading ? <ClipLoader loading={true} size={14} /> : snapshotItem?.mctr}</Text></Box>
                        }
                    </Box>
                }
            >
                <Box>
                    {children}
                </Box>
            </TooltipContainer>
        )
    }

}

export default TickerToolTipContainer