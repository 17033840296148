import React, { Component } from 'react'

import { Box } from '@mui/material'
import { BaseProps } from '../../types'
import { FONT_STYLES_BY_TYPE } from './Text'

export interface AbsolutePosition {
    top?: number
    bottom?: number
    left?: number
    right?: number
}

export interface FloatingContentsProps extends BaseProps {
    position: AbsolutePosition
}

export class FloatingContents extends Component<FloatingContentsProps> {

    render() {
        const { children, position, sx={} } = this.props

        return (
            <Box sx={{ 
                ...position,
                position: 'fixed',
                zIndex: '555 !important',
                lineHeight: '1.5rem',
                font: FONT_STYLES_BY_TYPE.text,
                ...sx,
            }}>
                {children}
            </Box>
        )
    }
}

export default FloatingContents;