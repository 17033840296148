import React from 'react';
import { Box, TableCell, TableRow, IconButton, ButtonBase, SxProps } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import moment from 'moment';
import Text from './styled/Text';
import { Table, TableProps as CustomTableProps } from './Table';
import PdComponent from './PdComponent';
import ExportMenu from './ChartExport';
import { TableRowData } from '../../common/types';
import { Theme } from '../../theme/theme';

export interface CommonTableProps extends CustomTableProps {
  noDataMessage?: string;
  // You can optionally define the rowProps or cellProps if needed
  rowProps?: any;
  valueCellProps?: any;
  /**
   * The data that populates the table.
   */
  rows: TableRowData[];
  redNegativeValues?: boolean;
  title?: string | React.ReactNode;
  exportTitle?: string;
  footer?: React.ReactNode;
  attribution?: React.ReactNode;
  footerAttributionSx?: SxProps<Theme>;
  footerOnlyInExport?: boolean;
  showExport?: boolean;
  headerContainerProps?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

interface State {
  expandedRows: Set<string>;
  exporting: boolean;

  // Sorting
  orderBy?: string;                 // e.g., a sortKey
  orderDirection?: 'asc' | 'desc';
}

export default class CommonTable extends PdComponent<CommonTableProps, State> {
  private tableContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: CommonTableProps) {
    super(props);
    this.state = {
      expandedRows: new Set<string>(),
      exporting: false,

      // sorting defaults
      orderBy: undefined,
      orderDirection: 'asc',
    };
  }

  /**
   * Toggles expansion on a given row.
   */
  toggleRow = (key: string) => {
    const newSet = new Set(this.state.expandedRows);
    if (newSet.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }
    this.setState({ expandedRows: newSet });
  };

  /**
   * Responsible for sorting the array of row data
   * based on the current `orderBy` and `orderDirection`.
   */
  private getSortedRows(rows: TableRowData[]): TableRowData[] {
    const { orderBy, orderDirection } = this.state;
    if (!orderBy) {
      return rows;
    }
    // Example: We interpret `orderBy` as an index or a custom key
    // to pick which 'values' index to compare
    const sorted = [...rows].sort((a, b) => {
      const aVal = this.extractValueForComparison(a, orderBy);
      const bVal = this.extractValueForComparison(b, orderBy);

      // Try numeric comparison first
      const aNum = parseFloat(aVal);
      const bNum = parseFloat(bVal);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        return aNum - bNum;
      }
      // fallback to string comparison
      return aVal.localeCompare(bVal);
    });

    // Reverse if 'desc'
    return orderDirection === 'desc' ? sorted.reverse() : sorted;
  }

  /**
   * Helper that picks a value from the row data to compare.
   * 
   * For example, if orderBy is "0", we might pick `row.values[0]`.
   */
  private extractValueForComparison(rowData: TableRowData, orderBy: string): string {
    // This is one approach. If your `sortKey` is an index into `values`,
    // parse it. Otherwise, you can define a custom mapping if you prefer.
    let index = parseInt(orderBy, 10);
    if (isNaN(index)) {
      index = this.props.headerColumns?.findIndex((col) => col.key === orderBy) || 0;
    }
    const val = rowData.values[index];
    return val != null ? String(val) : '';
  }

  /**
   * Callback for the Table's header to toggle sorting state.
   */
  private handleSort = (columnKey: string) => {
    const { orderBy, orderDirection } = this.state;
    if (orderBy === columnKey) {
      // toggle direction if user clicks same column
      this.setState({ orderDirection: orderDirection === 'asc' ? 'desc' : 'asc' });
    } else {
      // new column
      this.setState({ orderBy: columnKey, orderDirection: 'asc' });
    }
  };

  /**
   * Renders a table row (and potentially sub-rows if expanded).
   */
  renderRow = (rowData: TableRowData, isSubRow: boolean = false) => {
    const { rowProps = {}, valueCellProps = {}, redNegativeValues } = this.props;
    const {
      key,
      title,
      titleSx = {},
      valueSx = {},
      titleRowSpan,
      values,
      valueKeys,
      ignoreTitle,
      seperator,
      rows: subRows,
      rowProp
    } = rowData;

    const isExpanded = this.state.expandedRows.has(key || '');
    const shouldIncludeTitle = !ignoreTitle && title;

    // Separator row
    if (seperator) {
      return (
        <TableRow key={key || title} {...rowProps}>
          <TableCell
            sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', height: '2.5rem' }}
            colSpan={this.props.headerColumns?.length}
          />
        </TableRow>
      );
    }

    // The main row for this piece of data
    const rowsArray = [
      <TableRow key={key || title} {...rowProps} {...rowProp}>
        {shouldIncludeTitle && (
          <Box component={TableCell} rowSpan={titleRowSpan} sx={{ ...titleSx }}>
            {(subRows?.length ?? 0) > 0 && (
              <ButtonBase onClick={() => this.toggleRow(key || '')}>
                <IconButton size="small">
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <Text sx={{ ...titleSx }}>{title}</Text>
              </ButtonBase>
            )}
            {!subRows?.length && (
              <Text sx={{ ml: isSubRow ? '2.5rem' : undefined, ...titleSx }}>{title}</Text>
            )}
          </Box>
        )}

        {values.map((value: any, index: number) => {
          const cellKey = valueKeys && valueKeys[index] ? valueKeys[index] : `${index}-${value}`;
          const negativeSx = redNegativeValues && `${value}`.startsWith('-') ? { color: 'red' } : {};

          // If the first cell is the toggler for subRows instead of a separate title cell
          if (index === 0 && !shouldIncludeTitle && subRows?.length) {
            return (
              <TableCell key={cellKey} {...valueCellProps}>
                <ButtonBase onClick={() => this.toggleRow(key || '')}>
                  <IconButton size="small">
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  <Text sx={{ ...valueSx, ...negativeSx }}>{value}</Text>
                </ButtonBase>
              </TableCell>
            );
          }

          return (
            <TableCell key={cellKey} {...valueCellProps}>
              <Text
                sx={{
                  ml: isSubRow && index === 0 ? '2.5rem' : undefined,
                  ...valueSx,
                  ...negativeSx
                }}
              >
                {value}
              </Text>
            </TableCell>
          );
        })}
      </TableRow>
    ];

    // If expanded, render subRows
    if (isExpanded && subRows) {
      subRows.forEach((subRow: TableRowData) => {
        rowsArray.push(this.renderRow(subRow, true) as any);
      });
    }

    return rowsArray;
  };

  /**
   * Download the provided data URL (image) as a file.
   */
  downloadImage = (dataUrl: string, filename: string) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Capture the table container as an image and download it.
   */
  exportChart = async () => {
    if (!this.tableContainerRef.current) {
      return;
    }

    try {
      const { exportTitle, title } = this.props;
      const titleText = exportTitle ? exportTitle : typeof title === 'string' ? title : '';
      const time = moment().tz('America/New_York').format('YYYY-MM-DD.hh.mm.a');
      const name = `${titleText || 'exported-table'}-${time}.png`
        .replace(/ /g, '-')
        .toLowerCase();

      await this.setStateAsync({ exporting: true });
      const canvas = await html2canvas(this.tableContainerRef.current, {
        useCORS: true,
        imageTimeout: 1000,
        allowTaint: true,
        logging: true,
      });
      this.setState({ exporting: false });
      const image = canvas.toDataURL('image/png');
      this.downloadImage(image, name);
    } catch (error) {
      console.error('Error capturing div:', error);
    }
  };

  /**
   * The main render.
   */
  render() {
    const {
      rows,
      noDataMessage,
      containerSx = {},
      footerAttributionSx = {},
      headerContainerProps = {},
      footerOnlyInExport,
      exportTitle: _exportTitle,
      headerColumns,
      showExport,
      title,
      ...restProps
    } = this.props;

    const { exporting, orderBy, orderDirection } = this.state;
    const shouldShowFooter = this.props.footer !== undefined && footerOnlyInExport ? exporting : true;

    // Sort data before rendering
    const sortedRows = this.getSortedRows(rows);

    return (
      <Box
        ref={this.tableContainerRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: exporting ? '1rem' : undefined,
          ...containerSx,
        }}
      >
        {/* Header / Export Controls */}
        {(title || showExport) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '1rem',
              marginBottom: '1rem',
              ...headerContainerProps,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              {title && typeof title === 'string' && (
                <Text type='table_header'>{title}</Text>
              )}
              {title && typeof title !== 'string' && title}
            </Box>
            <Box sx={{ display: 'flex' }}>
              {!exporting && showExport && <ExportMenu onDownloadImage={this.exportChart} />}
            </Box>
          </Box>
        )}

        {/* Our custom Table component with sorting. */}
        <Table
          // Spread the rest of the props that might be relevant for Table
          {...restProps}
          // Sorting props
          onSort={this.handleSort}
          orderBy={orderBy}
          orderDirection={orderDirection}
          // Let the Table generate header columns if we have them
          headerColumns={headerColumns}
        >
          {/* If no rows, show noDataMessage */}
          {!sortedRows.length && noDataMessage && (
            <TableRow>
              <TableCell
                sx={{ textAlign: 'center' }}
                colSpan={headerColumns?.length || 1}
              >
                <Text>{noDataMessage}</Text>
              </TableCell>
            </TableRow>
          )}

          {/* Render each row (already sorted) */}
          {sortedRows.map((rowData) => this.renderRow(rowData))}
        </Table>

        {/* Footer & Attribution */}
        <Box sx={{ display: 'flex', ...footerAttributionSx }}>
          {shouldShowFooter && (
            <Box sx={{ display: 'flex', flex: 1 }}>
              {this.props.footer}
            </Box>
          )}
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            {this.props.attribution}
          </Box>
        </Box>
      </Box>
    );
  }
}
