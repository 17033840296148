import { ChartOptions, SelectOption, TableRowData } from '../common/types';
import {
    PROFILE_RESET,
    LOGOUT,
    PERFORMANCE_ATTRIBUTION_LOADING,
    PERFORMANCE_ATTRIBUTION_RECEIVED
} from '../actions/types/actions';
import moment from 'moment';
import { InternalPerformanceAttributionTickers } from '../actions/types/simplifyTools';

export interface PerformanceAttributionState {
  loading: boolean;
  tickers: InternalPerformanceAttributionTickers,
  tickerOptions: SelectOption[];
  selectedTicker: SelectOption | null;
  startDate: Date | null;
  endDate: Date | null;
  performanceAttributionData: TableRowData[];
  totalReturnsData: TableRowData[];
  assetClassReturns: TableRowData[];
  topTensData: TableRowData[];
  cumReturnVsBenchmarkChartData: ChartOptions;
  fundPerfRelativeToBenchmarkChartData: ChartOptions;
  cumReturnByAssetClassChartData: ChartOptions;
  assetClassTopTenData: {
    [key: string]: TableRowData[];
  }
  assetClassTopTenOptions: SelectOption[];
  hasResults: boolean;
  selectedAssetClassTopTen: SelectOption | null;
  asset_class_level_csv?: string;
  security_level_csv?: string;
}

export interface PerformanceAttributionProps extends Omit<PerformanceAttributionState, 'loading'> {}

const INITIAL_STATE: PerformanceAttributionState = { 
  loading: false,
  selectedTicker: null,
  startDate: moment().subtract('4', 'M').endOf('M').toDate(),
  endDate: moment().subtract(1, 'day').toDate(),
  performanceAttributionData: [],
  totalReturnsData: [],
  assetClassReturns: [],
  topTensData: [],
  cumReturnVsBenchmarkChartData: {},
  fundPerfRelativeToBenchmarkChartData: {},
  cumReturnByAssetClassChartData: {},
  tickers: {},
  tickerOptions: [],
  assetClassTopTenData: {},
  assetClassTopTenOptions: [],
  hasResults: false,
  selectedAssetClassTopTen: null,
};

export default (state = INITIAL_STATE, action: any): PerformanceAttributionState => {
  const { payload, type } = action

  switch (type) {
    case PERFORMANCE_ATTRIBUTION_LOADING:
        return { ...state, loading: payload }
    case PERFORMANCE_ATTRIBUTION_RECEIVED:
        return { ...state, ...payload }
    case LOGOUT:
      return { ...state, ...INITIAL_STATE }
    default:
        return state;
  }
};

