import React, { Component } from 'react'

import { Box, SxProps, Theme } from '@mui/material'
import { BaseProps } from '../../types'
import FloatingContents from './FloatingContents'

const ESTIMATED_BOTTOM_MARGIN = 70
const BOTTOM_DISTANCE_THRESHOLD = 250

export interface Position {
    top?: number
    bottom?: number
    left?: number
    right?: number
}

export interface FloatingContainerProps extends BaseProps {
    contentsSx?: SxProps<Theme>
    aboveOffests?: Position
    belowOffsets?: Position
}
export interface FloatingContainerState {
    rect?: DOMRect
    position: Position
}

export class FloatingContainer extends Component<FloatingContainerProps, FloatingContainerState> {
    view?: HTMLElement = undefined

    constructor(props: FloatingContainerProps) {
        super(props)

        this.state = {
            position: { }
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onUpdateSize, true)
        window.addEventListener('resize', this.onUpdateSize);
    }
  
    componentWillUnmount() {
        window.removeEventListener('resize', this.onUpdateSize);
        document.removeEventListener('scroll', this.onUpdateSize, true)
    }
    onUpdateRef = (view: HTMLElement) => {
        if(!view) {
            return
        }
        this.view = view
        this.onUpdateSize()
    }

    onUpdateSize = () => {
        if(!this.view) {
            return
        }
        
        const rect = this.view.getBoundingClientRect()
        const test = this.view.getClientRects()[0]

        this.updatePosition(this.view.getClientRects()[0])
    }

    updatePositionWithOffsets = (position: Position, offsets?: Position) => {
        const updatedPosition = { ...position }
        const { top: offsetTop, bottom: offsetBottom, left: offsetLeft, right: offsetRight } = offsets || {}
        const { top, bottom, left, right } = position
        if (offsetTop !== undefined && top !== undefined) {
            updatedPosition.top = top ? top + offsetTop : offsetTop
        }
        if (offsetBottom !== undefined && bottom !== undefined) {
            updatedPosition.bottom = bottom ? bottom + offsetBottom : offsetBottom
        }
        if (offsetLeft !== undefined && left !== undefined) {
            updatedPosition.left = left ? left + offsetLeft : offsetLeft
        }
        if (offsetRight !== undefined && right !== undefined) {
            updatedPosition.right = right ? right + offsetRight : offsetRight
        }

        return updatedPosition;
    }

    updatePosition = ({ x, y, height, width }: DOMRect) => {
        const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
        const bottomEdge = windowHeight - ESTIMATED_BOTTOM_MARGIN;
    
        let position: Position = {};
    
        const totalHeight = y + BOTTOM_DISTANCE_THRESHOLD;
        if (totalHeight > bottomEdge) {
            position = { bottom: windowHeight - y };
            position = this.updatePositionWithOffsets(position, this.props.aboveOffests)
        } else {
            position = { top: y + height };
            position = this.updatePositionWithOffsets(position, this.props.belowOffsets)
        }
    
        const totalWidth = x;
        if (totalWidth > windowWidth) {
            position = { ...position, right: windowWidth - (x + width) };
        } else {
            position = { ...position, left: x };
        }

        this.setState({ position })
        
    }

    render() {
        const { children, sx = {}, contentsSx = {} } = this.props
        const { position } = this.state
        return (
            <Box sx={{
                position: 'relative',
                ...sx,
            }}
                ref={this.onUpdateRef}>
                <FloatingContents position={position} sx={contentsSx}>
                    {children}
                </FloatingContents>
            </Box>
        )
    }
}

export default FloatingContainer